import React from "react";
import { useNavigate } from "react-router-dom";
import AppLogo from "./assets/AppLogo.svg";
import instagram from "./assets/instagram.svg";
import facebook from "./assets/facebook.svg";
import twitter from "./assets/twitter.svg";
import linkdin from "./assets/linkdin.svg";
import whatsapp from "./assets/whatsapp.svg";

import "./assets/appfooter.scss";
const AppFooter = () => {
  const Navigate = useNavigate();
  return (
    <div className="footer-homepage">
      <div className="footer-logo">
        <img src={AppLogo} alt="logo" className="footer-logo-img" />
        <div className="social-medias">
          <a
            href="https://www.instagram.com/lifeatbullagritech?igsh=cHgxeTVlMGpubTFv"
            target="_blank"
            rel="noreferrer"
            className="textdeco-none pointer"
          >
            <img
              src={instagram}
              style={{ height: "30px", width: "30px" }}
              className="social-media-icon pointer"
              alt="img-soc"
            />
          </a>
          <img
            src={facebook}
            style={{ height: "29px", width: "30px" }}
            className="social-media-icon pointer"
            alt="img-soc"
          />
          <a
            href="https://chat.whatsapp.com/KCY3aGMdc99LNAXEcdsw3X"
            target="_blank"
            rel="noreferrer"
            className="textdeco-none pointer"
          >
            <img
              style={{ height: "30px", width: "30px" }}
              src={whatsapp}
              className="social-media-icon pointer"
              alt="img-soc"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/bull-agritech/"
            target="_blank"
            rel="noreferrer"
            className="textdeco-none pointer"
          >
            <img
              src={linkdin}
              className="social-media-icon"
              alt="img-soc"
              style={{ height: "29px", width: "36px" }}
            />
          </a>
        </div>
      </div>
      <div className="footer-nav-container">
        <div className="contact-container">
          <span className="title">Contact</span>
          <span
            className="list-item"
            onClick={() =>
              (window.location.href = "mailto:divyajit@bullagritech.in")
            }
          >
            divyajit@bullagritech.in
          </span>
          <span
            className="list-item"
            onClick={() => window.open("tel:9558669405")}
          >
            +91 9558669405
          </span>
          <span
            className="list-item"
            onClick={() =>
              window.open("https://maps.app.goo.gl/oSx6RAV6HXx9EX9v7")
            }
          >
            A-604, Ganesh Glory 11,
            <br /> Off SG Highway, <br />
            Ahmedabad 382470
          </span>
        </div>
        <div className="footer-navigation">
          <span className="title">Company</span>
          <span
            className="list-item"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              Navigate("/aboutus");
            }}
          >
            Our Journey
          </span>
          <span
            className="list-item"
            onClick={() => {
              Navigate("/");
              setTimeout(() => {
                let elem = document.getElementById("milestone-div-nav-scroll");
                elem?.scrollIntoView();
              }, 300);
            }}
          >
            Milestones
          </span>
          <a
            href="https://bull-agritech.medium.com/"
            target="_blank"
            rel="noreferrer"
            className="textdeco-none"
          >
            <span className="list-item">Blogs</span>
          </a>
        </div>
      </div>
      <div className="social-medias1">
        <a
          href="https://www.instagram.com/lifeatbullagritech?igsh=cHgxeTVlMGpubTFv"
          target="_blank"
          rel="noreferrer"
          className="textdeco-none pointer"
        >
          <img
            src={instagram}
            style={{ height: "30px", width: "30px" }}
            className="social-media-icon pointer"
            alt="img-soc"
          />
        </a>
        <img
          src={facebook}
          style={{ height: "29px", width: "30px" }}
          className="social-media-icon pointer"
          alt="img-soc"
        />
        <a
          href="https://chat.whatsapp.com/KCY3aGMdc99LNAXEcdsw3X"
          target="_blank"
          rel="noreferrer"
          className="textdeco-none pointer"
        >
          <img
            style={{ height: "30px", width: "30px" }}
            src={whatsapp}
            className="social-media-icon pointer"
            alt="img-soc"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/bull-agritech/"
          target="_blank"
          rel="noreferrer"
          className="textdeco-none pointer"
        >
          <img
            src={linkdin}
            className="social-media-icon"
            alt="img-soc"
            style={{ height: "29px", width: "36px" }}
          />
        </a>
      </div>
      <div id="contact-us-scroll"></div>
    </div>
  );
};

export default AppFooter;
