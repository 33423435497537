import React from "react";
import backgroundImageHome from "./assets/about-background.svg";
import backgroundImageHomeMobile from "./assets/about-mobile.svg";
import growdth from "./assets/growth.svg";
import growdthMobile from "./assets/growdth-mobile.svg";
import footerMobile from "./assets/mobile-footer.svg";
import footerMain from "./assets/main-footer.svg";
import "./assets/aboutus.scss";
import Problem from "./Problem";
import Solution from "./Solution";
import GreenBullet from "./GreenBullet";
import WhiteBullet from "./WhiteBullet";
import { ReactSVG } from "react-svg";

const AboutUs = () => {
  return (
    <div className="about-us-container-main">
      <ReactSVG className="about-back-image" src={backgroundImageHome} />
      <img
        src={backgroundImageHomeMobile}
        alt="back-img"
        className="about-back-image-mobile"
      ></img>
      <div className="home-page-text-container">
        <span className="home-title">
          We are <span className="home-title-green">building</span> what
        </span>
        <span className="home-title">others have gave up on.</span>
        <span className="home-title">
          The <span className="home-title-green">last mile</span> Agri
        </span>
        <span className="home-title">supply chain.</span>
      </div>
      <div className="about-us-container">
        <h3 className="title">Supply Chain and Tech</h3>
        <span className="desc">
          We connect farmers directly to the agri-commodities processors to sell
          their non-perishable crop by availing maximum price realization. At
          other end, commodity processors get direct access to the farmer, which
          gives them control over the last mile of the supply chain.
          <br />
          <br />
          We are currently operating with 7 collection centers and 30000+
          farmers connected with us digitally.
        </span>
        <span className="desc1">
          We connect farmers directly to the {"\n"}
          agri-commodities processors to sell{"\n"}
          their non-perishable crop by availing{"\n"}
          maximum price realization. At other{"\n"}
          end, commodity processors get direct{"\n"}
          access to the farmer, which gives them{"\n"}
          control over the last mile of the supply{"\n"}
          chain.
          <br />
          <br />
          We are currently operating with 7{"\n"} collection centers and 30000+
          farmers {"\n"}connected with us digitally.
        </span>
      </div>
      <div className="card-container">
        <div
          className="card0"
          id="card0"
          onMouseEnter={() => {
            if (document.getElementById("card0").className === "card1") {
              document.getElementById("card0").className = "card1";
              document.getElementById("card1").className = "card0";
            } else {
              document.getElementById("card0").className = "card0";
              document.getElementById("card1").className = "card1";
            }
          }}
          onMouseLeave={() => {
            document.getElementById("card0").className = "card0";
            document.getElementById("card1").className = "card1";
          }}
        >
          <Problem />
          <span className="title">The problem</span>
          <span className="desc">
            {/* <img src={whiteBullet} alt="icon" className="white-bullet" /> */}
            <WhiteBullet />
            Farmers sell their commodities through a long chain of
            intermediaries and middlemen that significantly reduces their
            profits.
          </span>
          <span className="desc">
            {/* <img src={whiteBullet} alt="icon" className="white-bullet" /> */}
            <WhiteBullet />
            Farmers have atrocious customer experience at local Mandis, waiting
            for hours on a daily basis to sell their goods.
          </span>
          <span className="desc">
            {/* <img src={whiteBullet} alt="icon" className="white-bullet" /> */}
            <WhiteBullet />
            Quality assessments in local Mandis are done with human touch and
            loose prediction, hence there is no technical basis on commodity
            will be priced which results into the exploitation of the farmer.
          </span>
        </div>
        <div
          className="card1"
          id="card1"
          onMouseEnter={() => {
            if (document.getElementById("card1").className === "card0") {
              document.getElementById("card1").className = "card1";
              document.getElementById("card0").className = "card0";
            } else {
              document.getElementById("card1").className = "card0";
              document.getElementById("card0").className = "card1";
            }
          }}
          onMouseLeave={() => {
            document.getElementById("card1").className = "card1";
            document.getElementById("card0").className = "card0";
          }}
        >
          {/* <img src={solutionIcon} className="problem-image" alt="icon" /> */}
          <Solution />
          <span className="title">Our Solution</span>
          <span className="desc">
            {/* <img src={greenBullet} alt="icon" className="white-bullet" /> */}
            <GreenBullet />
            We are a platform that handles tech, quality assessment, logistics
            and payment protection and thus aims to help both farmers and
            commodity processors connect and conduct a trade through a more
            efficient process.
          </span>
          <span className="desc">
            {/* <img src={greenBullet} alt="icon" className="white-bullet" /> */}
            <GreenBullet />
            On one end, farmers get better prices, and on the other end
            processors get huge financial benefits from procuring the material
            directly from the farmers
          </span>
          <span className="desc">
            {/* <img src={greenBullet} alt="icon" className="white-bullet" /> */}
            <GreenBullet />
            We are building an AI enabled farmer negotiation platform which will
            help farmers get the most accurate idea of the price realization
            even before getting out for a trade.
          </span>
        </div>
      </div>
      <div className="middle-container">
        <img src={growdth} alt="background" className="back-image" />
        <img
          src={growdthMobile}
          alt="background"
          className="back-image-mobile"
        />
        <span className="growdth-title">Growth and development</span>
        <div className="about-us-container1">
          <h3 className="title">The Road Ahead</h3>
          <span className="desc">
            We aspire to create India’s largest commodity supply chain by
            leveraging tech and our network of farmers and commodity processors.
            <br />
            <br />
            our aim is to transform the farmer into a trader by giving him
            warehousing and financing solutions bundled with a marketplace to
            sell his produce. For farmer, the experience of trading his
            commodity would be same as trading stocks on Zerodha.
            <br />
            <br />
            We further plan to leverage the distribution of output linkage to
            create brands in agri-input industry.
          </span>
          <span className="desc1">
            We aspire to create India’s largest commodity{"\n"}supply chain by
            leveraging tech and our network {"\n"} of farmers and commodity processors.
            <br />
            <br />
            our aim is to transform the farmer into a trader{"\n"}by giving him
            warehousing and financing {"\n"}solutions bundled with a marketplace to
            sell {"\n"}his produce. For farmer, the experience of {"\n"}trading his
            commodity would be same as trading {"\n"}stocks on Zerodha.
            <br />
            <br />
            We further plan to leverage the distribution of {"\n"}output linkage to
            create brands in agri-input {"\n"}industry.
          </span>
        </div>
      </div>
      <div className="about-footer">
        <img src={footerMain} className="footer-img-main" alt="img-footer" />
        <img
          src={footerMobile}
          className="footer-img-mobile"
          alt="img-footer"
        />
        {/* <Button className="join-us-btn">Join us</Button> */}
      </div>
    </div>
  );
};

export default AboutUs;
