import React from "react";
import { ReactSVG } from "react-svg";
import { Button, Grid } from "@mui/material";
import "./assets/homepage.scss";
import rightAero from "./assets/right-aero.svg";
import backgroundImageHome from "./assets/home-background.svg";
import backgroundImageHomeMobile from "./assets/farmer-mobile.svg";
import centralDiagram from "./assets/central-diagram.svg";
import centralDiagramMobile from "./assets/central-diagram-mobile.svg";
import bottomMobile from "./assets/mobile-bottom.svg";
import cycleDiagram from "./assets/cycle-diagram.svg";
import Slider from "../../Components/Slider";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  let Navigate = useNavigate();
  return (
    <div className="home-page-container">
      <ReactSVG className="home-back-image" src={backgroundImageHome} />
      <ReactSVG
        className="home-back-image-mobile"
        src={backgroundImageHomeMobile}
      />
      <div className="home-page-container-abs">
        <div className="home-page-text-container">
          <span className="home-title">Revolutionising the</span>
          <span className="home-title-green">non-perishable</span>
          <span className="home-title">produce supply Chain</span>
        </div>
        <div className="desc-container">
          <span className="home-page-decription">
            Driven by the digital revolution, we source quality <br />
            assured non-perishable commodities directly <br />
            to the processing factories.
          </span>
        </div>
        <Button className="learn-more-btn" onClick={() => Navigate("/aboutus")}>
          Our Journey &nbsp;
          <img src={rightAero} alt="right-aero" />
        </Button>
      </div>
      <div className="about-us-container">
        <h3 className="title">About us</h3>
        <span className="desc">
          Bull Agritech is a commodity supply chain company which aims to
          connect farmers directly to the factories to conduct the trade by
          taking care of everything in between including logistics, quality
          assessment and payment protection.
        </span>
      </div>
      <div className="diagram-one">
        <img src={centralDiagram} className="centeral-diagram" alt="imgg" />
        {/* <ReactSVG className="centeral-diagram" src={centralDiagram} /> */}
        <img
          src={centralDiagramMobile}
          className="centeral-diagram-mobile"
          alt="imgg"
        />
        <div
          id="milestone-div-nav-scroll"
          style={{
            position: "absolute",
            bottom: "102px",
            fontSize: "0px",
          }}
        >
          555
        </div>
      </div>
      <div className="milestone-container">
        <h1 className="title">Milestones</h1>
        <Grid container className="info-container">
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">30,000+</span>
            <span className="description">Farmers connected digitally</span>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">60+ Cr</span>
            <span className="description">
              Rupees worth of produce sold with us
            </span>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">6500+</span>
            <span className="description">
              Farmer trusted us with their produce
            </span>
          </Grid>
          <Grid item md={3} sm={6} xs={12} className="info">
            <span className="title">7</span>
            <span className="description">Warehouse collection centers</span>
          </Grid>
        </Grid>
      </div>
      <div className="cycle-diagram">
        <img
          src={cycleDiagram}
          className="cycle-diagram-img"
          alt="cycle-diagram-img"
        />
        <img
          src={bottomMobile}
          className="cycle-diagram-img-mobile"
          alt="cycle-diagram-img"
        />
        <div className="txt-one">
          <span className="first-line">Benefits for</span>
          <span className="second-line">Farmers</span>
        </div>
        <div className="txt-two">
          <span className="first-line">Benefits for</span>
          <span className="second-line">Agri-businesses</span>
        </div>
        <div className="static-container-text">
          <span className="static-string-title">
            What do people have to say about us?
          </span>
        </div>
        <div className="slider-container">
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
