import React, { useEffect, useState } from "react";
import { router } from "./router/router";
import { RouterProvider } from "react-router-dom";
import "./global.scss";
import BullLoader from "./Components/BullLoader";

function App() {
  const [first, setfirst] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setfirst(true);
    }, 1000);
  }, []);
  return (
    <div className="App">
      {!first ? <BullLoader /> : <RouterProvider router={router} />}
    </div>
  );
}

export default App;
