import React from "react";

const WhiteBullet = () => {
  return (
    <svg
      width="25px"
      height="25px"
      style={{ minWidth: "30px", minHeight: "30px", paddingRight: "10px" }}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5378"
        cy="12.3807"
        r="11.5"
        stroke="white"
        id="outer-circle"
      />
      <circle
        cx="12.5378"
        cy="12.3807"
        r="8.42456"
        fill="white"
        id="inner-circle"
      />
    </svg>
  );
};

export default WhiteBullet;
