import React from "react";

const Problem = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <circle
        cx="29.7064"
        cy="29.6111"
        r="29.6068"
        fill="#EEFCEF"
        id="circlex"
      />
      <path
        d="M32.0891 38.0908H27.3205C26.9514 38.0908 26.6519 38.3904 26.6519 38.7595C26.6519 39.1289 26.9514 39.4284 27.3205 39.4284H32.0891C32.4583 39.4284 32.7581 39.1289 32.7581 38.7595C32.7582 38.3904 32.4584 38.0908 32.0891 38.0908Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M28.4769 41.9886C28.5676 42.5889 29.0804 43.0511 29.7065 43.0511C30.3319 43.0511 30.8459 42.5891 30.9362 41.9886C31.7548 41.9403 32.4054 41.2676 32.4054 40.4369H27.0044C27.0044 41.2681 27.6568 41.9413 28.4769 41.9886Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M29.7065 20.0292C30.1998 20.0292 30.5986 19.6299 30.5986 19.1375V17.0629C30.5986 16.5703 30.1999 16.1711 29.7065 16.1711C29.2137 16.1711 28.8149 16.5703 28.8149 17.0629V19.1375C28.815 19.6299 29.2137 20.0292 29.7065 20.0292Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M23.8546 21.3286C24.0298 21.569 24.3015 21.6966 24.5778 21.6966C24.7588 21.6966 24.9424 21.6413 25.1009 21.5263C25.499 21.2371 25.5878 20.6795 25.2986 20.2811L24.0794 18.6022C23.7895 18.2042 23.2329 18.1144 22.8335 18.4045C22.4355 18.6935 22.3466 19.2513 22.6358 19.6497L23.8546 21.3286Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M35.5571 34.4007C35.2679 34.0026 34.7107 33.9143 34.3115 34.2035C33.9138 34.493 33.825 35.0507 34.115 35.449L35.3349 37.128C35.5089 37.3679 35.7807 37.4956 36.0571 37.4956C36.2388 37.4956 36.4217 37.4403 36.5801 37.3253C36.9782 37.0358 37.0669 36.478 36.7771 36.0796L35.5571 34.4007Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M21.6832 24.3198L19.7097 23.678C19.2368 23.5256 18.7381 23.782 18.5856 24.2506C18.433 24.7186 18.6892 25.2219 19.1576 25.3743L21.1312 26.0162C21.2233 26.0458 21.3156 26.0602 21.407 26.0602C21.7831 26.0602 22.1323 25.8207 22.2552 25.4436C22.4078 24.9756 22.1516 24.4722 21.6832 24.3198Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M40.2548 30.3546L38.2818 29.7137C37.813 29.5624 37.3105 29.8181 37.1582 30.2861C37.0057 30.7546 37.2627 31.2578 37.731 31.4101L39.7045 32.051C39.796 32.0806 39.8882 32.0949 39.9797 32.0949C40.3558 32.0949 40.705 31.855 40.8279 31.4784C40.98 31.0097 40.7235 30.507 40.2548 30.3546Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M21.1315 29.7137L19.1573 30.3537C18.6889 30.5056 18.432 31.0084 18.5845 31.477C18.7064 31.8541 19.0562 32.0941 19.4324 32.0941C19.5232 32.0941 19.6164 32.0795 19.7079 32.0499L21.6818 31.4099C22.1505 31.2579 22.4074 30.755 22.2549 30.2866C22.1035 29.8184 21.6043 29.5624 21.1315 29.7137Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M38.0066 26.0602C38.0981 26.0602 38.1902 26.0458 38.2818 26.0162L40.2548 25.3753C40.7235 25.2229 40.9801 24.7199 40.8279 24.2515C40.6753 23.7834 40.1704 23.5275 39.7045 23.679L37.731 24.3198C37.2626 24.4721 37.0057 24.9752 37.1582 25.4435C37.2809 25.8203 37.6304 26.0602 38.0066 26.0602Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M23.8558 34.4004L22.6346 36.0783C22.3447 36.4767 22.4328 37.0344 22.8317 37.324C22.9893 37.4394 23.173 37.4949 23.3548 37.4949C23.631 37.4949 23.9027 37.3677 24.0768 37.1277L25.2979 35.4497C25.5879 35.0514 25.4997 34.4936 25.1009 34.204C24.7039 33.9128 24.1438 34.0021 23.8558 34.4004Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M35.334 18.6021L34.1148 20.281C33.8256 20.6794 33.9144 21.237 34.3126 21.5262C34.471 21.6411 34.6546 21.6965 34.8356 21.6965C35.1119 21.6965 35.3836 21.5689 35.5584 21.3284L36.7776 19.6495C37.0667 19.2511 36.9779 18.6933 36.5798 18.4043C36.1794 18.1135 35.624 18.2031 35.334 18.6021Z"
        fill="#66BB6A"
        id="itemx"
      />
      <path
        d="M36.1472 27.8975C36.1472 24.3397 33.2632 21.4556 29.7058 21.4556C26.1477 21.4556 23.2637 24.3397 23.2637 27.8975C23.2637 29.3983 23.7799 30.7765 24.6422 31.8705C25.3979 32.8293 26.1135 33.876 26.7337 34.9293V37.0691H32.6413V34.9861C33.2558 33.9617 34.0502 32.7822 34.7679 31.8716C35.631 30.7772 36.1472 29.3993 36.1472 27.8975Z"
        fill="#66BB6A"
        id="itemx"
      />
    </svg>
  );
};

export default Problem;
