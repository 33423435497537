import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import comment from "./assets/comment-icon.svg";
const Slider = () => {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  return (
    <div>
      <AutoplaySlider
        play={true}
        showTimer={false}
        cancelOnInteraction={false}
        mobileTouch={true}
        interval={5000}
        organicArrows={false}
      >
        <div className="slider-content pointer">
          <img src={comment} alt="icon" className="img-user" />
          <span className="comment-title-name">Aravindbhai Patel</span>
          <span className="comment-text">
            We used to go to an APMC 60kms away to get better prices of our
            crop. Bull Company helped me save 6,000 Rs. with convenience of
            selling from home.
          </span>
        </div>
        <div className="slider-content pointer">
          <img src={comment} alt="icon" className="img-user" />
          <span className="comment-title-name">
            Almost every APMC trader in North Gujarat
          </span>
          <span className="comment-text">
            Why the hell did you chose our region to initiate this startup?
          </span>
        </div>
        <div className="slider-content pointer">
          <img src={comment} alt="icon" className="img-user" />
          <span className="comment-title-name">Jitubhai Parmar</span>
          <span className="comment-text">
            My land has limited irrigation. Earlier when I used to sell my crop
            to local traders or go to APMC, I would always get very low price
            for my produce despite slight variation in quality. But, now I sell
            my crop to Bull Company, where I get only required price deduction
            based on multiple quality parameters of my produce.
          </span>
        </div>
      </AutoplaySlider>
    </div>
  );
};

export default Slider;
