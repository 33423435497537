import React from "react";

const Solution = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
    >
      <ellipse
        cx="30.0687"
        cy="30.1895"
        rx="29.6068"
        ry="30.1853"
        id="circlex"
        fill="#66BB6A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        id="itemx"
        d="M36.5215 21.2277C39.1494 21.8494 40.1546 23.4161 41.4149 25.5724H36.5215V21.2277ZM21.1638 25.7535C21.6783 25.7535 22.0518 26.0067 22.0518 26.5985C22.0518 27.0976 21.6556 27.5024 21.1663 27.5038H15.8016C15.3111 27.5038 14.9136 27.9093 14.9136 28.4091C14.9136 28.9092 15.3111 29.3145 15.8016 29.3145H23.7935C24.2886 29.3145 24.685 29.7195 24.685 30.2198C24.685 30.7199 24.2875 31.1252 23.797 31.1252H15.8016C15.3111 31.1252 14.9136 31.5305 14.9136 32.0305C14.9136 32.5306 15.3111 32.9359 15.8016 32.9359H17.7552V35.6519C17.7552 36.152 18.1527 36.5573 18.6432 36.5573H20.4282C20.7464 38.1553 22.1313 39.3337 23.7639 39.3337C25.3966 39.3337 26.7815 38.1553 27.0997 36.5573H36.1161C36.4343 38.1553 37.8193 39.3337 39.4519 39.3337C41.0845 39.3337 42.4694 38.1553 42.7876 36.5573H44.3358C44.8263 36.5573 45.2238 36.152 45.2238 35.6519V30.2198C45.2238 27.5634 42.4757 27.3871 42.4731 27.3831H35.6335C35.143 27.3831 34.7455 26.9778 34.7455 26.4777V21.0457H18.6432C18.1527 21.0457 17.7552 21.4509 17.7552 21.951V23.9428H16.6896C16.1991 23.9428 15.8016 24.3481 15.8016 24.8481C15.8016 25.3482 16.1991 25.7535 16.6896 25.7535H21.1638ZM40.603 34.6895C41.2387 35.3377 41.2387 36.3889 40.603 37.0371C39.5804 38.0796 37.8239 37.3379 37.8239 35.8632C37.8239 34.3887 39.5804 33.647 40.603 34.6895ZM24.9151 34.6895C25.5508 35.3377 25.5508 36.3889 24.9151 37.0371C23.8925 38.0796 22.1359 37.3379 22.1359 35.8632C22.1359 34.3887 23.8925 33.647 24.9151 34.6895Z"
        fill="#EEFCEF"
      />
    </svg>
  );
};

export default Solution;
